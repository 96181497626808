import { Component, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  hideHeader = false;
  burgerMenuActive = false;


  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      // Code, der nur im Browser-Kontext ausgeführt wird

      setInterval(() => {
        let currentInnerWidth = window.innerWidth;
        if (currentInnerWidth <= 1400) {
          this.hideHeader = true;
        } else {
          this.hideHeader = false;
        }
      }, 100);
    }
  }

  toggleBurgerMenu(burgerMenuActive: any) {
    this.burgerMenuActive = !this.burgerMenuActive;
    if (!burgerMenuActive) {
    document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  closeBurgerMenuWithLinks() {
    this.burgerMenuActive = true;
    this.toggleBurgerMenu(this.burgerMenuActive);
  }

  closeBurgerMenu(burgerMenuActive: any) {
    this.burgerMenuActive = true;
    this.toggleBurgerMenu(this.burgerMenuActive);
  }
  
}