<section class="section-title" data-aos="zoom-in">

    <div class="title">
        <div class="title-h1-h2 font-family-bubbler">
            <div class="title-underdiv">
                <h2>Melanie Henner</h2>
                <h1>Du bist auf der Suche nach deiner inneren Mitte?</h1>
            </div>
          
        </div>
    
        <div class="img-div">
            <img class="img-meli">
        </div>
    </div>
   
   
</section>
