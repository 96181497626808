import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-massages',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    NgClass,
    NgIf,
    CommonModule,
    MatExpansionModule,
  ],
  templateUrl: './massages.component.html',
  styleUrl: './massages.component.scss',
})
export class MassagesComponent {
  massages: { massage: string; description: string }[];
  expandedIndex: number | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.massages = data.massages;
  }

  removeOverflowHidden() {
    document.body.style.overflow = 'visible';
    this.closeQuiz();
  }

  closeQuiz() {
    this.dialog.closeAll();
  }
}
