import { Component } from '@angular/core';
import { AboutMeComponent } from './about-me/about-me.component'
import { ContactComponent } from './contact/contact.component'
import { TitleComponent } from './title/title.component';
import { OfferComponent } from './offer/offer.component';
import { SingleOfferComponent } from './single-offer/single-offer.component';

@Component({
  selector: 'app-main-content',
  standalone: true,
  imports: 
  [
    AboutMeComponent,
    ContactComponent,
    TitleComponent,
    OfferComponent, 
    SingleOfferComponent
   ],
    
  templateUrl: './main-content.component.html',
  styleUrl: './main-content.component.scss'
})
export class MainContentComponent {

}

