<section id="contact" data-aos="zoom-in">
    <div class="section-contact">
        <div [class.contact-left-german]="germanActivated" class="contact-left">
            <h1 class="font-family-bubbler" id="h1Contact">Kontakt</h1>
            <h2 id="h2Contact">Wie kann ich dir helfen?</h2>
            <span id="textContact">Schreibe mir hier einfach deine Nachricht. Ich melde mich.</span>
            <p id="text2Contact" ><b> Kontaktiere mich!</b></p>
            <div id="button-up"></div>
        </div>
    
        <form id="contactForm" action="https://in-your-balance.de/send_mail/send_mailMeli.php" method="post">
            <input id="nameInput" required class="font-family-montserrat" placeholder="Name" type="text" name="name">
            <input id="emailInput" required class="font-family-montserrat" placeholder="Email" type="email" name="email">
            <textarea id="textareaInput" required class="font-family-montserrat" placeholder="Nachricht" name="message"></textarea>
            <div [class.form-german]="germanActivated" class="checkbox-div">
              <span id="error" class="error"></span>
              <span id="sucess" class="sucess"></span>
              <input required class="checkbox" type="checkbox">
              <p>
               <ng-container>Ich habe die <a [routerLink]="['imprint']">Datenschutzerklärung</a> gelesen und stimme der Verarbeitung meiner Daten wie beschrieben zu!</ng-container>
              </p>
            </div> 
            <button [class.cursor-not-allowed]="!formValid" (click)="sendMail()" id="buttonContact" type="submit" class="font-family-montserrat">Senden</button>
        </form>
    </div>
    
</section>

