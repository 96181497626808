import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { SingleOfferComponent } from './../single-offer/single-offer.component';

@Component({
  selector: 'app-offer',
  standalone: true,
  imports: [MatCardModule, MatDialogModule],
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss',
})
export class OfferComponent {
  therapies: {
    title: string;
    description: string;
    notice: string;
    image: string;
  }[] = [
    {
      title: 'Stressbewältigung',
      description:
        'Immer mehr Menschen leiden heutzutage unter einem erhöhten Stresslevel. Viele können die Anspannung und den Stress im Alltag nicht mehr abbauen und bemerken oft erst sehr spät, wie belastet sie wirklich sind – meist dann, wenn sich körperliche oder psychische Probleme einstellen.' +
        'Ein dauerhaft hoher Stresspegel führt nachweislich zu körperlichen Erkrankungen und kann sowohl Depressionen als auch Burnout hervorrufen. Oft handelt es sich um einen schleichenden Prozess, der erst wahrgenommen wird, wenn es schon zu spät ist. Daher ist es heute wichtiger denn je, auf Stressbewältigungsmethoden zurückzugreifen, die den Stress langfristig abbauen und dafür sorgen, dass wir gelassener werden.' +
        '<br><br><b>Hilfe zur Selbsthilfe!</b><br><br>' +
        'Ich möchte dir hier einige Methoden vorstellen, mit denen es dir gelingt, deinen Alltag entspannter zu gestalten und das Stressniveau zu senken. Doch zunächst ist es wichtig zu verstehen, was Stress für uns persönlich bedeutet.' +
        'Jeder negative Einfluss – sei es ein Streit mit einer nahestehenden Person, eine als belastend empfundene Arbeit oder das eigene negative Denken – hat immer negative Auswirkungen auf uns. Energie folgt der Aufmerksamkeit, und selbst negative Gedanken ziehen uns Energie und mindern unseren Energiefluss erheblich. Dies erklärt, warum viele Menschen nicht mehr fröhlich und ausgeglichen sind, sondern nur noch gehetzt und genervt durch den Alltag jagen.<br>' +
        'Ein wichtiger Punkt ist, dass der größte Stress tatsächlich von uns selbst verursacht wird. Dies geschieht, weil wir jede Situation aus verschiedenen Blickwinkeln und basierend auf unseren bisherigen Lebenserfahrungen betrachten und bewerten. Somit haben wir einen großen Einfluss darauf, ob wir uns täglich einem hohen negativen Stresspotenzial ausgesetzt sehen.<br>' +
        'Achte deshalb darauf, deinen Stresspegel in einem guten Gleichgewicht zu halten, denn sonst geht dir viel positive Energie verloren, was dich auf lange Sicht krank machen kann.<br>' +
        'Wir unterscheiden zwischen positivem und negativem Stress. Unsere Vorstellung von Stress ist meist negativ, doch es kommt darauf an, wie wir Stress empfinden und bewerten. Negativer Stress sind Dinge, die uns belasten, die wir ungern tun oder die uns Sorgen bereiten. Beispielsweise führen negative Gedanken dazu, dass wir psychisch immer mehr leiden und sich ein Gefühl der Unzufriedenheit oder Hilflosigkeit breitmacht.<br>' +
        'Positiver Stress hingegen, auch Eustress genannt, kann Glückshormone auslösen und uns beflügeln. Nehmen wir das Beispiel Sport: Sport kann auf eine gewisse Art auch Stress auslösen, uns gleichzeitig aber auch pushen und beflügeln. Oder wenn du ein Hobby hast, für das du üben musst, kann dich das durchaus stressen, gleichzeitig aber auch glücklich machen.<br>' +
        'Positiver Stress hingegen, auch Eustress genannt, kann Glückshormone auslösen und uns beflügeln. Nehmen wir das Beispiel Sport: Sport kann auf eine gewisse Art auch Stress auslösen, uns gleichzeitig aber auch pushen und beflügeln. Oder wenn du ein Hobby hast, für das du üben musst, kann dich das durchaus stressen, gleichzeitig aber auch glücklich machen.<br><br><br>' +
        'Welche Methoden helfen uns zu entspannen? Wie kannst du dein Leben positiv beeinflussen?',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },

    {
      title: 'SPORT/BEWEGUNG',
      description:
        'Sport und Bewegung sind ausgezeichnete Methoden zur Stressbewältigung. Körperliche Aktivität führt zur Freisetzung von Endorphinen, den sogenannten "Glückshormonen", die das Wohlbefinden steigern und ein Gefühl der Entspannung und Zufriedenheit vermitteln. Regelmäßiger Sport hilft dabei, Anspannungen abzubauen und den Geist von stressigen Gedanken zu befreien. Zudem verbessert Bewegung die Schlafqualität, was essentiell für die Regeneration des Körpers und die Stressbewältigung ist. Sportliche Betätigung fördert auch das Selbstbewusstsein und die Selbstwirksamkeit, da man durch das Erreichen sportlicher Ziele ein Gefühl der Kontrolle und Kompetenz entwickelt. Insgesamt trägt Bewegung zu einer besseren körperlichen und geistigen Gesundheit bei, was die Widerstandsfähigkeit gegenüber Stress erhöht. Ich kann euch hier das Minitrampolin empfehlen, Yoga, Aqua-Gymnastik und Nordic-Walking bzw. flottes Spazierengehen.',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Meditation',
      description:
        'Meditation kann ein wirksames Mittel zur Stressbewältigung sein, indem sie Körper und Geist in einen Zustand tiefer Entspannung versetzt. Durch regelmäßige Meditation kann man lernen, die eigene Atmung zu kontrollieren und den Geist zu beruhigen. Dies führt zu einer Reduktion von Stresshormonen im Körper und einer Verbesserung des allgemeinen Wohlbefindens. Meditation fördert zudem die Achtsamkeit, was dabei hilft, Stressfaktoren besser zu erkennen und bewusster mit ihnen umzugehen. Langfristig kann Meditation die Resilienz gegenüber stressigen Situationen erhöhen und zu einem ausgeglicheneren und zufriedeneren Leben beitragen.',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Entspannungsübungen',
      description:
        'Entspannungsübungen wie Autogenes Training und Progressive Muskelentspannung sind effektive Techniken zur Stressbewältigung. Beim Autogenen Training lernt man, durch Selbstsuggestion gezielt Entspannung im Körper herbeizuführen, was zu einer Reduktion von Stresssymptomen wie Muskelverspannungen und erhöhter Herzfrequenz führt. Die Progressive Muskelentspannung basiert darauf, verschiedene Muskelgruppen bewusst anzuspannen und danach zu entspannen. Diese Methode hilft, ein besseres Körperbewusstsein zu entwickeln und Spannungen aktiv zu lösen. Beide Techniken fördern eine tiefere Entspannung und können helfen, den Geist zu beruhigen und Stress abzubauen. Durch regelmäßiges Üben können diese Entspannungsübungen zu einer gesteigerten Stressresistenz und einem insgesamt verbesserten Wohlbefinden führen.',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Yoga',
      description:
        'Yoga ist eine wirkungsvolle Methode zur Stressbewältigung, da es körperliche Bewegung mit Atemtechniken und Meditation kombiniert. Durch die verschiedenen Asanas (Körperhaltungen) wird der Körper gedehnt und gestärkt, was Verspannungen löst und die Flexibilität verbessert. Die kontrollierte Atmung während der Übungen fördert die Sauerstoffzufuhr und beruhigt das Nervensystem, was zu einer tiefen Entspannung führt. Meditation und Achtsamkeitsübungen im Yoga helfen, den Geist zu fokussieren und von stressigen Gedanken zu befreien. Regelmäßiges Yoga kann den Blutdruck senken, die Schlafqualität verbessern und die allgemeine Stimmung heben, wodurch die Fähigkeit, mit Stress umzugehen, deutlich gesteigert wird.',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Mindset',
      description:
        'Das eigene Mindset spielt eine entscheidende Rolle bei der Stressbewältigung. Eine positive Einstellung und eine optimistische Sichtweise können dabei helfen, stressige Situationen besser zu meistern. Indem man Herausforderungen als Chancen für Wachstum und Lernen betrachtet, anstatt sich von ihnen überwältigen zu lassen, wird der Stress reduziert. Ein achtsamer Umgang mit den eigenen Gedanken und eine bewusste Konzentration auf den gegenwärtigen Moment können ebenfalls helfen, den Stresspegel zu senken. Durch Selbstreflexion und das Erkennen negativer Denkmuster kann man diese gezielt verändern und einen konstruktiveren Umgang mit Stress entwickeln. Insgesamt führt ein positives Mindset zu mehr Gelassenheit und Resilienz im Alltag.',
      notice: '',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Atherische Öle',
      description:
        'Ätherische Öle können bei der Stressbewältigung auf vielfältige Weise helfen. Die Düfte bestimmter Öle wie Lavendel, Kamille und Bergamotte haben beruhigende Eigenschaften, die das Nervensystem entspannen und Angstgefühle lindern können. Durch Inhalation oder Anwendung auf der Haut können diese Öle direkt auf das limbische System im Gehirn wirken, das für Emotionen und Stressreaktionen verantwortlich ist. Das Einatmen ätherischer Öle kann die Produktion von Stresshormonen reduzieren und ein Gefühl der Ruhe und Entspannung fördern. Aromatherapie-Massagen oder das Hinzufügen von ätherischen Ölen zu einem warmen Bad können ebenfalls helfen, Muskelverspannungen zu lösen und den Geist zu beruhigen. Insgesamt tragen ätherische Öle zu einem ganzheitlichen Ansatz bei, der sowohl körperliche als auch emotionale Aspekte der Stressbewältigung anspricht.',
      notice: 'Finde heraus welche Öle zu dir passen',
      image: 'src/assets/images/yoga.png',
    },
    {
      title: 'Massage',
      description:
        'Eine Massage kann auf vielfältige Weise zur inneren Balance und zum allgemeinen Wohlbefinden beitragen.' +
        '<br><br> 1. Stressabbau Massage wirkt direkt auf das Nervensystem und kann helfen, Stress abzubauen. Durch die gezielte Berührung und Behandlung der Muskulatur werden Spannungen gelöst, die durch emotionale und körperliche Belastungen entstehen. Weniger Stress führt zu einer entspannteren Stimmung und trägt zur inneren Balance bei.' +
        '<br><br> 2. Förderung der Durchblutung Eine Massage verbessert die Blutzirkulation, was die Versorgung der Organe und Gewebe mit Sauerstoff und Nährstoffen fördert. Eine gute Durchblutung kann Verspannungen lösen und dazu beitragen, dass der Körper besser in der Lage ist, sich selbst zu regulieren, was zu einem ausgeglicheneren Gefühl führt.' +
        '<br><br> 3. Lösung von Verspannungen Muskelverspannungen sind häufig eine Quelle von Unbehagen und Stress. Durch Massagetechniken, die auf spezifische Muskelgruppen abzielen, können Blockaden und Spannungen gelöst werden. Die Entspannung der Muskulatur kann eine tiefere körperliche und emotionale Entspannung herbeiführen.' +
        '<br><br> 4. Achtsamkeit und Körperbewusstsein Massagen ermutigen oft zur Achtsamkeit und fördern das Bewusstsein für den eigenen Körper. Die achtsame Wahrnehmung während einer Massage hilft, sich mit den eigenen Empfindungen und Gefühlen auseinanderzusetzen, was zu einer besseren Selbstkenntnis und inneren Ruhe führt.' +
        '<br><br> 5. Hormoneller Ausgleich Massage kann die Produktion von entstressenden Hormonen wie Serotonin und Dopamin erhöhen, während gleichzeitig das Stresshormon Cortisol gesenkt wird. Dieses hormonelle Gleichgewicht spielt eine wichtige Rolle für das emotionale Wohlbefinden und die innere Stabilität.' +
        '<br><br> 6. Emotionale Entlastung Oft können während oder nach einer Massage emotionale Blockaden gelöst werden. Manche Menschen erleben in diesem Zusammenhang eine tiefere emotionale Entlastung, was zu einer verbesserten emotionalen Balance führen kann. Massagen können als eine Art „Ventil“ wirken, um angestaute Emotionen freizusetzen.' +
        '<br><br> 7. Förderung des Schlafs Eine entspannende Massage kann dabei helfen, den Schlaf zu verbessern, was eine entscheidende Rolle für das allgemeine Wohlbefinden und die innere Balance spielt. Ein gesunder Schlaf hat positive Auswirkungen auf die Stimmung, die geistige Klarheit und die körperliche Gesundheit.' +
        '<br><br> 8. Zusammenhang zwischen Körper und Geist Massageprinzipien beruhen auf dem Verständnis, dass Körper und Geist miteinander verbunden sind. Eine Massage kann dazu beitragen, diese Verbindung zu stärken, indem sie sowohl körperliche als auch geistige Blockaden löst und den Energiefluss im Körper harmonisiert. Dies führt zu einem ganzheitlicheren Gefühl der Ausgeglichenheit.' +
        '<br><br> Fazit Insgesamt kann eine Massage in vielerlei Hinsicht zur inneren Balance führen, indem sie Stress reduziert, die Muskulatur entspannt, körperliches und emotionales Wohlbefinden fördert und das Körperbewusstsein stärkt. Regelmäßige Massagen können Teil einer ganzheitlichen Strategie zur Förderung von Gesundheit und Wohlbefinden sein, die sowohl den Körper als auch den Geist berücksichtigt.',
      notice: 'Welche Arten von Massage gibt es?',
      image: 'src/assets/images/yoga.png',
    },
  ];

  constructor(public dialog: MatDialog) {}

  disableClose = false;

  openDialog(index: number) {
    const selectedTherapy = this.therapies[index];
    // Hier könntest du das Dialogfenster öffnen und das ausgewählte Element übergeben
    this.dialog.open(SingleOfferComponent, {
      data: {
        title: selectedTherapy.title,
        description: selectedTherapy.description,
        notice: selectedTherapy.notice,
      },
      disableClose: true,
    });
  }
}
