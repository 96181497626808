import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { QuestionsComponent } from './../questions/questions.component'
import { MassagesComponent } from './../../massages/massages.component'
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-single-offer',
  standalone: true,
  imports: [
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    CommonModule
  ],
  templateUrl: './single-offer.component.html',
  styleUrl: './single-offer.component.scss'
})
export class SingleOfferComponent {
  selectedTherapy: { title: string, description: string, notice: string };
  selectedMassages: { title: string, description: string };
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.selectedTherapy = data;
    this.selectedMassages = data;
  }

  questions: { question: string, answer1:string, answer2: string }[] = [
    { 
      question: 'Leidest du unter Schlafstörung?', 
      answer1: 'Ja',
      answer2: 'Nein',
    },
  ];

massages: { massage: string, description: string }[] = [
  { 
    massage: 'Aromaölmassage',
    description: 'Ätherische Öle können eine Massage in vielerlei Hinsicht bereichern und deren positive Effekte verstärken.' +
    '<br><br> 1. Vertiefung der Entspannung: Ätherische Öle wie Lavendel, Kamille oder Bergamotte sind für ihre beruhigenden Eigenschaften bekannt. ' +
    'Ihr Duft kann helfen, den Geist zu beruhigen und eine tiefere Entspannung während der Massage zu fördern. ' +
    'Die Inhalation dieser Öle kann das Stresslevel senken und ein Gefühl von Frieden und Ruhe hervorrufen.'+
    '<br><br> 2. Förderung der Durchblutung Öle wie Pfefferminze oder Rosmarin können die Durchblutung anregen. Wenn sie in die Massageölmischung integriert werden, tragen sie dazu bei, die Blutzirkulation zu fördern, was Muskelverspannungen reduzieren und die allgemeine Muskel- und Geweberegeneration unterstützen kann.'+
    '<br><br> 3. Schmerzlinderung Einige ätherische Öle, wie Eukalyptus und Ingwer, sind bekannt für ihre schmerzlindernden und entzündungshemmenden Eigenschaften. Ihre Anwendung während der Massage kann bei der Behandlung von Muskel- und Gelenkschmerzen helfen und eine schnellere Linderung bieten.'+
    '<br><br> 4. Emotionale Balance Ätherische Öle wirken nicht nur auf den Körper, sondern auch auf die Emotionen. Öle wie Ylang-Ylang und Neroli können emotionale Blockaden lösen und die Stimmung heben. Diese emotionalen Vorteile können die Massageerfahrung insgesamt verbessern und zu einem Gefühl der inneren Balance beitragen.'+
    '<br><br> 5. Antibakterielle und antiseptische Eigenschaften Einige ätherische Öle, wie Teebaumöl oder Lavendel, besitzen antibakterielle und antiseptische Eigenschaften. Die Verwendung dieser Öle in der Massage kann dabei helfen, das Immunsystem zu stärken und die Hautgesundheit zu fördern, indem Bakterien und Keime abgewehrt werden'+
    '<br><br> 6. Erhöhung des Achtsamkeitselements Der Duft von ätherischen Ölen kann die Sinne aktivieren und eine achtsamere Massageerfahrung fördern. Wenn der Klient die verschiedenen Aromen erlebt, wird er ermutigt, sich mehr auf den gegenwärtigen Moment und die körperlichen Empfindungen zu konzentrieren, was zu einer tieferen Entspannung und inneren Balance führt.'+
    '<br><br> 6. Erhöhung des Achtsamkeitselements Der Duft von ätherischen Ölen kann die Sinne aktivieren und eine achtsamere Massageerfahrung fördern. Wenn der Klient die verschiedenen Aromen erlebt, wird er ermutigt, sich mehr auf den gegenwärtigen Moment und die körperlichen Empfindungen zu konzentrieren, was zu einer tieferen Entspannung und inneren Balance führt.'+
    '<br><br> 8. Schaffung einer angenehmen Atmosphäre Der angenehme Duft von ätherischen Ölen kann auch dazu beitragen, eine entspannende und beruhigende Atmosphäre zu schaffen, die den gesamten Massagesitzung fördert. Dies trägt dazu bei, dass sich der Klient wohler fühlt und die Massage in vollen Zügen genießen kann.'+
    '<br><br> Fazit Die Kombination von ätherischen Ölen und Massage kann die Vorteile beider Methoden verstärken und eine ganzheitliche Erfahrung bieten, die sowohl körperliche als auch emotionale Aspekte des Wohlbefindens anspricht. Durch die gezielte Auswahl und Anwendung ätherischer Öle kann eine Massage nicht nur entspannend, sondern auch revitalisierend und heilend wirken.'
  },
  { 
    massage: 'Massage des Körpers',
    description: 'Diese Massage bezieht sich auf den gesamten Körper und hilft, Muskelverspannungen zu lösen.',
  },
  { 
    massage: 'Beinmassage',
    description: 'Diese Massage bezieht sich auf die Beine',
  },
];

disableClose = false;

openQuiz(index: number) {
  document.body.style.overflow = 'hidden';

  const selectedQuestions = this.questions[index];
  const dialogRef = this.dialog.open(QuestionsComponent, {
    data: {
      question: selectedQuestions.question,
      answer1: selectedQuestions.answer1,
      answer2: selectedQuestions.answer2,
    },
    disableClose: true
  });
  this.setBackBodyScroll(dialogRef);
}

openMassage() {
  document.body.style.overflow = 'hidden';
  const dialogRef = this.dialog.open(MassagesComponent, {
    data: {
      massages: this.massages
    },
    disableClose: true
  });
}

setBackBodyScroll(dialogRef:any) {
  dialogRef.afterClosed().subscribe(() => {
    document.body.style.overflow = 'auto'; // Scrollen wieder aktivieren
  });
}
}