<section data-aos="flip-left">

<div>
  <h1>Finde dein Thema</h1>
</div>

<div id="methoden" class="button-div">
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(0)">Stressbewältigung
    <img src="/assets/images/stressbewältigung.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(1)">Sport/Bewegung
    <img src="./../../../assets/images/asanas.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(2)">Meditation
    <img src="./../../../assets/images/pranayamas.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(3)">Entspannungsübungen
  <img class="imageEntspannungsÜbung" src="./../../../assets/images/entspannungsübungen.png">
    </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(4)">Yoga
    <img src="/assets/images/yoga1.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(5)">Mindset
    <img src="./../../../assets/images/mindset.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(6)">Ätherische Öle
    <img src="./../../../assets/images/ätherischeÖle.png">
  </button>
  <button class="button font-family-montserrat" mat-raised-button (click)="openDialog(7)">Massage
    <img src="./../../../assets/images/massage.png">
  </button>
</div>
</section>