
<div data-aos="zoom-in" id="aboutMe1" class="about-me">
    <h2>Über mich</h2>
    <span>
      <b class="text-block-one">Sehnst du dich nach innerer Ruhe, <br>Zufriedenheit und innerem Gleichgewicht?</b><br>
      <b class="font-size-24 text-in-centre">Hast du das Gefühl, das Leben rast an dir vorbei und du bist nur noch am Funktionieren?</b><br>
      <div class="text-in-centre">Doch JETZT möchtest du dein Leben ändern? Du möchtest wieder in eine gesunde Balance kommen? Du möchtest dein Leben selber gestalten?</div><br>
      <div class="text-in-centre">Wie schön, dass du hier bist! Gerne möchte ich dich auf diesem spannenden Weg begleiten und dir zur Seite stehen!</div><br>
      Das Leben ist ein stetiger Fluss, und wir stehen immer wieder vor neuen Herausforderungen. Doch mit einer Vielzahl wunderbarer Möglichkeiten ist es möglich, sich immer wieder zu zentrieren und innere Ruhe und Balance zu finden.<br><br>
      Hallo, ich bin Meli, Frau, Mutter, Yogalehrerin, Entspannungspädagogin, kreative Realistin, Optimistin.
      Ich begleite dich in meinen Kursen und meinem Mentorin auf deinem Weg zu innerem Frieden, mehr Beweglichkeit und deiner kraftvollen Mitte. Ich sehe dich als Individuum und wir erarbeiten gemeinsam bedürfnisorientierte tools für dich!
      <br>
      Meine Angebote für dich:
    </span>
  </div>