<header class="header-menu">
 
    <h1>INNER BALANCE</h1>
    <div [class.display-none]="hideHeader" class="menu-links">
      <a id="aboutMe" href="#aboutMe1">Über mich</a>
      <a href="#methoden">Methoden</a>
      <a href="#">sonstiges</a>
      <a href="#h1Contact">Kontakt</a>
    </div>
  
    <img class="burger-menu" (click)="toggleBurgerMenu(burgerMenuActive)" [class.display-none]="!hideHeader" src="assets/images/burgerMenuWhite.png">
  </header>

  <div [class.show-burger-menu]="burgerMenuActive" [class.hide-burger-menu]="!burgerMenuActive">
    <button (click)="closeBurgerMenu(burgerMenuActive)" class="close-burgermenu" mat-mini-fab color="primary">X</button>
    <div class="burger-menu-links">
        <a (click)="closeBurgerMenuWithLinks()" href="#aboutMe">Über mich</a>
        <a (click)="closeBurgerMenuWithLinks()" href="#methoden">Methoden</a>
        <a (click)="closeBurgerMenuWithLinks()" href="#">Sonstiges</a>
        <a (click)="closeBurgerMenuWithLinks()" href="#h1Contact">Kontakt</a> 
    </div>
</div>
