import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-questions',
  standalone: true,
  imports: [  
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    NgClass, NgIf],
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.scss'
})
export class QuestionsComponent {
  selectedQuestions: { question: string, answer1: string, answer2: string, answer3: string, answer4: string };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.selectedQuestions = data;
  }

  questions: { question: string, answer1:string, answer2: string, answer3: string, answer4: string }[] = [
    { 
      question: 'Leidest du unter Schlafstörung?', 
      answer1: 'Ja',
      answer2: 'Nein',
      answer3: ``,
      answer4: ``
    },

    { 
      question: 'Hast du Migräne?', 
      answer1: 'Ja',
      answer2: 'Nein',
      answer3: ``,
      answer4: ``
    },

    { 
      question: 'Bist du träge?', 
      answer1: 'Ja',
      answer2: 'Nein',
      answer3: ``,
      answer4: ``
    },

    {
      question: 'Fühlst du dich oft traurig?', 
      answer1: 'Ja',
      answer2: 'Nein',
      answer3: ``,
      answer4: ``
    }
  ];

  currentQuestionIndex: number = 0;
  yesIsClicked:boolean = false;
  recommendedOils: string[] = [];
  quizFinished = false;

  nextQuestion() {
    this.currentQuestionIndex++;
    if (this.currentQuestionIndex < this.questions.length) {
        this.selectedQuestions = this.questions[this.currentQuestionIndex];
    } else {
        this.quizFinished = true;

        // Erstelle die Antworten mit den jeweiligen Notizen
        this.selectedQuestions = { 
            question: 'Diese Öle helfen dir', 
            answer1: this.recommendedOils[0] ? `1. ${this.recommendedOils[0]} - ${this.notices[0] || ''}` : '', 
            answer2: this.recommendedOils[1] ? `2. ${this.recommendedOils[1]} - ${this.notices[1] || ''}` : '', 
            answer3: this.recommendedOils[2] ? `3. ${this.recommendedOils[2]} - ${this.notices[2] || ''}` : '',
            answer4: this.recommendedOils[3] ? `4. ${this.recommendedOils[3]} - ${this.notices[3] || ''}` : ''
        };
    }
}

  notices: string[] = [];

  toggleNotice() {
    // Leere das Notices-Array
    this.notices = [];

    // Durchlaufe das recommendedOils-Array und setze die entsprechenden Notizen
    this.recommendedOils.forEach((oil, index) => {
        switch (oil) {
            case 'Bellisima':
                this.notices[index] = 'gegen Migräne. Massiere das Öl auf deine Stirn und deine Schläfen in kreisenden Bewegungen ein.';
                break;
            case 'Peppermint':
                this.notices[index] = 'für besseren Schlaf';
                break;
            case 'Zitrus':
                this.notices[index] = 'für mehr Energie';
                break;
            case 'Banane':
                this.notices[index] = 'gegen Traurigkeit';
                break;
            default:
                this.notices[index] = ''; // Standardfall, falls kein spezifisches Öl gefunden wird
                break;
        }
    });
}
  
  // Methode zum Behandeln der Antwort
  handleAnswer(answer: string) {
    if (answer === this.selectedQuestions.answer1) {
        this.yesIsClicked = true; // Setze die Variable, wenn die erste Antwort (Ja) geklickt wurde
        this.pushOil(answer);
    } else {
        this.yesIsClicked = false;
    }
    this.toggleNotice(); // Setze die Notizen basierend auf den empfohlenen Ölen
    this.nextQuestion(); // Lade die nächste Frage
}

  pushOil(answer: string) {
    if (answer === this.selectedQuestions.answer1) {
      if (this.selectedQuestions.question === "Leidest du unter Schlafstörung?" && !this.recommendedOils.includes("Peppermint")) {
        this.recommendedOils.push("Peppermint");
      }
      if (this.selectedQuestions.question === "Hast du Migräne?" && !this.recommendedOils.includes("Bellisima")) {
        this.recommendedOils.push("Bellisima");
      }
      if (this.selectedQuestions.question === "Bist du träge?" && !this.recommendedOils.includes("Zitrus")) {
        this.recommendedOils.push("Zitrus");
      }
      if (this.selectedQuestions.question === "Fühlst du dich oft traurig?" && !this.recommendedOils.includes("Banane")) {
        this.recommendedOils.push("Banane");
      }
}
  }

  closeQuiz() {
    this.dialog.closeAll();
  }
}
