<div class="single-offer-div">
    <h2 mat-dialog-title>{{ selectedTherapy.title }}</h2>
    <img src="./../../../assets/images/yoga.png">
    <mat-dialog-content class="single-offer-content" [innerHTML]="selectedTherapy.description">{{ selectedTherapy.description }}</mat-dialog-content>
    <button *ngIf="selectedTherapy.notice === 'Finde heraus welche Öle zu dir passen'" 
    (click)="openQuiz(0)" class="quiz-button">
{{ selectedTherapy.notice }}
</button>

<!-- Button for Massage -->
<button *ngIf="selectedTherapy.notice === 'Welche Arten von Massage gibt es?'" 
    (click)="openMassage()" class="quiz-button">{{ selectedTherapy.notice }}</button>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>  
</div>