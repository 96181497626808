import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [RouterLink, NgIf],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  germanActivated: boolean = false;
  http = inject(HttpClient);

  constructor() {}

  ngOnInit(): void {
 
    this.setValidation();
  }

  
 emptyInputs() {
    let nameInput = document.getElementById('nameInput') as HTMLInputElement;
    let emailInput = document.getElementById('emailInput') as HTMLInputElement;
    let textareaInput = document.getElementById('textareaInput') as HTMLTextAreaElement;
    let checkbox: any = document.querySelector('.checkbox');
    nameInput.value = '';
    emailInput.value = '';
    textareaInput.value = '';
    checkbox.checked = false;
    this.showSucess();
  }

  showSucess() {
      let sucess:any  = document.getElementById('sucess');
      sucess.classList.add('sucess');
      sucess.textContent = 'successfully sent';
      setTimeout(() => {
        sucess.textContent = '';
      }, 3000);
  }

  showError() {
       // Wenn das Formular ungültig ist, zeige eine Fehlermeldung an oder handle es entsprechend
       let error:any  = document.getElementById('error');
       error.textContent = 'please fill in all fields';
       setTimeout(() => {
         error.textContent = '';
       }, 3000);
   }
   
   formValid = false;

   setValidation() {
    let nameInput = document.getElementById('nameInput') as HTMLInputElement;
    let emailInput = document.getElementById('emailInput') as HTMLInputElement;
    let textareaInput = document.getElementById('textareaInput') as HTMLTextAreaElement;
    let checkbox: HTMLInputElement = document.querySelector('.checkbox') as HTMLInputElement;
    
    setInterval(() => {
        if (nameInput && emailInput && textareaInput && checkbox) {
            // Überprüfe, ob alle Eingabefelder nicht leer sind und die Checkbox ausgewählt ist
            if (nameInput.value.trim() !== '' && emailInput.value.trim() !== '' && textareaInput.value.trim() !== '' && checkbox.checked) {
                this.formValid = true;
            } else {
            }
        }
    }, 100); 
}
  
  sendMail() {
    // Zugriff auf das Formular über das Template-Referenzobjekt
    let form = document.getElementById('contactForm') as HTMLFormElement;
    // Überprüfen, ob das Formular gültig ist
    if (form.checkValidity()) {
        this.formValid = true;
        // Wenn das Formular gültig ist, führe den Versand aus
        var formData = new FormData(form);
        // Fetch API um die E-Mail zu senden
        this.emptyInputs();
        fetch(form.action, {
            method: 'POST',
            body: formData
        }) 
        .then(response => {
            if (response.ok) {
                // Zurücksetzen des Formulars nach erfolgreicher Sendung
                setTimeout(() => {
                    form.reset();
                }, 2000);
            } 
        })
        .catch(error => {
            console.error('Fehler beim Senden der E-Mail:', error);
            alert('Fehler beim Senden der E-Mail.');
        });
    } else {
     this.showError();
    }
  }
}
