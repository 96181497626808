
<div class="dialog-massage">
<div class="massage-main-div">
<mat-accordion *ngFor="let massage of massages; let i=index">
  <mat-expansion-panel class="massage-div" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ massage.massage }}</mat-panel-title>
    </mat-expansion-panel-header>
    <p [innerHTML]="massage.description"></p>
  </mat-expansion-panel>
</mat-accordion>



</div>
<mat-dialog-actions class="massage-div-close-button">
  <button  (click)="removeOverflowHidden()" class="close-button" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
</div>